import { memo } from 'react';

import { AnchorButton } from 'components/Button/AnchorButton';
import { Button } from 'components/Button/Button';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useCurrentSavedSearch } from 'modules/search/hooks/useCurrentSavedSearch';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { userDashboardSavedItemsRoute } from 'modules/userDashboard/routing/userDashboard.routes';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { useModal } from 'zustand-stores/modalStore';

import { SaveSearchButtonHolder } from './SaveSearchButton.styled';
import { SaveSearchButtonTooltip } from './SaveSearchButtonTooltip';

type Props = {
  saveSearch: () => void;
  searchFacets: SearchFacet[];
};

export const SaveSearchButton = memo(({ saveSearch, searchFacets }: Props) => {
  const { savedSearch, savingSearchStatus } = useCurrentSavedSearch({
    searchFacets,
  });

  const { modal } = useModal();

  if (savingSearchStatus === 'EDITING' && savedSearch) {
    return (
      <SaveSearchButtonHolder data-qa-id="save-search">
        <Tooltip
          content={<SaveSearchButtonTooltip savedSearch={savedSearch} />}
          size="large"
          placement="bottom-center"
          tooltipWidth={300}
          renderCondition={savingSearchStatus === 'EDITING' && !modal}
          variant="brand-green"
          withArrow={false}
          closeable
        >
          <AnchorButton
            icon={{
              name: 'bookmark',
              type: 'normalized',
            }}
            variant="secondary"
            href={userDashboardSavedItemsRoute.with({ tab: 'SEARCH' })}
            data-qa-id="save-search"
          >
            {getText('My Saved Searches')}
          </AnchorButton>
        </Tooltip>
      </SaveSearchButtonHolder>
    );
  }

  if (savingSearchStatus === 'SAVED' && savedSearch) {
    return (
      <AnchorButton
        icon={{
          name: 'bookmark',
          type: 'normalized',
        }}
        variant="secondary"
        href={userDashboardSavedItemsRoute.with({ tab: 'SEARCH' })}
        data-qa-id="save-search"
      >
        {getText('My Saved Searches')}
      </AnchorButton>
    );
  }

  return (
    <Button
      onClick={async (e) => {
        e.stopPropagation();
        trackClicked('Get Email Alerts');
        saveSearch();
      }}
      icon={{
        name: 'bookmark',
        type: 'normalized',
      }}
      variant="secondary"
      data-qa-id="save-search"
    >
      {getText('Save Search')}
    </Button>
  );
});
