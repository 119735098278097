import { Box } from 'components/Box';
import { SmallTile } from 'components/Tile';
import {
  SEARCH_HIT_IMAGE_SIZE,
  SEARCH_HIT_IMAGE_SIZE_SMALL,
} from 'utils/constants/searchHitImageSizes';
import { uuid } from 'utils/functional/string/uuid';

import { SearchLoadingSectionLine } from './SearchLoadingSectionLine';

type Props = {
  hideLabel?: boolean;
};

export function SearchLoadingSectionMockResultsItem({ hideLabel }: Props) {
  return (
    <SmallTile key={uuid()}>
      <Box display={['block', 'flex']}>
        <Box position="relative" flex="0 0 auto" pr={[0, 30]} pb={[24, 0]}>
          <SearchLoadingSectionLine
            width={[
              SEARCH_HIT_IMAGE_SIZE_SMALL,
              null,
              null,
              null,
              SEARCH_HIT_IMAGE_SIZE,
            ]}
            height={[
              SEARCH_HIT_IMAGE_SIZE_SMALL,
              null,
              null,
              null,
              SEARCH_HIT_IMAGE_SIZE,
            ]}
            borderRadius="50%"
          />
        </Box>
        <Box
          position="relative"
          width="100%"
          flex="1 1 auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {!hideLabel && (
            <Box display="flex" alignItems="center" mb={32}>
              <SearchLoadingSectionLine
                width={16}
                height={16}
                mr={12}
                borderRadius="50%"
              />
              <SearchLoadingSectionLine width={120} height={10} />
            </Box>
          )}
          <SearchLoadingSectionLine width="82.5%" height={20} />
          <SearchLoadingSectionLine width="95%" height={12} mt={16} />
          <SearchLoadingSectionLine width={160} height={8} mt={32} />
        </Box>
      </Box>
    </SmallTile>
  );
}
