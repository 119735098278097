import { radiusStringToInt } from 'modules/search/helpers/radius';
import { filterToIndex } from 'modules/search/helpers/savedSearches';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { MainStoreSearchRadiusState } from 'store/ducks/searchRadius';
import { MainStoreMainSearchState } from 'store/mainSearch/MainStoreMainSearchState';

import { almostSavedSearchSerializableDataStructure } from './almostSavedSearchSerializableDataStructure';

type Args = {
  mainSearch: MainStoreMainSearchState;
  searchRadius: MainStoreSearchRadiusState;
  searchFacets: SearchFacet[];
};

export function getSearchPropertiesFromState({
  mainSearch,
  searchRadius,
  searchFacets,
}: Args) {
  const { text, ...locationWithoutText } = mainSearch.searchLocation || {};
  return {
    filters: almostSavedSearchSerializableDataStructure({
      filtersById: mainSearch.filters,
      searchFacets,
    }),
    location: {
      text,
      ...locationWithoutText,
    },
    text: mainSearch.query,
    radius: radiusStringToInt(searchRadius.value),
    index: filterToIndex(mainSearch.filters.type),
  };
}
