import styled from 'styled-components';

import { Checkbox } from 'components/Checkbox';
import { colors } from 'theme/theme';

export const EditSavedSearchFormCheckbox = styled(Checkbox)`
  align-items: center;

  label > div {
    color: ${colors.lightContentGrey};
    font-size: 14px;
    line-height: 14px;
  }
`;
