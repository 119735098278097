import { Box } from 'components/Box';
import { useFormContext } from 'components/Form/FormContext';
import { InputField } from 'components/Form/InputField';
import { SubmitButton } from 'components/Form/SubmitButton/SubmitButton';

import { EditSavedSearchFormCheckbox } from './EditSavedSearchForm.styled';

export function EditSavedSearchForm() {
  const { values, fieldQaIdPrefix, updateValue } = useFormContext();

  return (
    <>
      <Box mb="24px">
        <InputField label={getText('Name your search')} name="name" noMargin />
      </Box>
      <Box mb="24px">
        <EditSavedSearchFormCheckbox
          label={getText('Email Alerts for this search')}
          data-qa-id={`${fieldQaIdPrefix}-notify-by-email`}
          name="notifyByEmail"
          checked={values.notifyByEmail}
          onChange={(checked) => updateValue('notifyByEmail', checked)}
          toggle
        />
      </Box>
      <SubmitButton fullWidth>{getText('Update')}</SubmitButton>
    </>
  );
}
