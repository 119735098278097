import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ApiSavedSearch } from 'api/savedSearch/types/ApiSavedSearch';
import { getSearchPropertiesFromState } from 'modules/search/helpers/savedSearch/getSearchPropertiesFromState';
import { removeUnsaveableFilters } from 'modules/search/helpers/savedSearch/removeUnsaveableFilters';
import { searchesAreEqual } from 'modules/search/helpers/savedSearch/searchesAreEqual';
import { serializeFiltersForSavedSearch } from 'modules/search/helpers/savedSearch/serializeFiltersForSavedSearch';
import { filterToIndex } from 'modules/search/helpers/savedSearches';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { useMainSelector } from 'store/hooks/useMainSelector';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';

type Args = {
  searchFacets: SearchFacet[];
};

export function useCurrentSavedSearch({ searchFacets }: Args): {
  savedSearch: ApiSavedSearch | null | undefined;
  savingSearchStatus: 'UNSAVED' | 'EDITING' | 'SAVED';
} {
  const location = useLocation();

  const { user } = useUserEnvironment();
  const mainSearch = useMainSelector((state) => state.mainSearch);
  const searchRadius = useMainSelector((state) => state.searchRadius);

  const savedSearch = useMemo(() => {
    if (!user) return null;

    const searchState = getSearchPropertiesFromState({
      mainSearch,
      searchRadius,
      searchFacets,
    });

    const search = removeUnsaveableFilters({
      ...searchState,
      index: filterToIndex(mainSearch.filters.type),
      filters: serializeFiltersForSavedSearch({
        filtersById: mainSearch.filters,
        queryParams: queryString.parse(location.search),
        searchFacets,
      }),
    });

    return user.savedSearches.find((s) => searchesAreEqual(s, search));
  }, [user, mainSearch, searchRadius, searchFacets, location.search]);

  const savingSearchStatus = useMemo(
    () => user?.savingSearchStatus || (savedSearch ? 'SAVED' : 'UNSAVED'),
    [user, savedSearch],
  );

  return {
    savedSearch,
    savingSearchStatus,
  };
}
