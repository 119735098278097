import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { sprintf } from 'sprintf-js';

import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';
import { radiusStringToInt } from 'modules/search/helpers/radius';
import { almostSavedSearchSerializableDataStructure } from 'modules/search/helpers/savedSearch/almostSavedSearchSerializableDataStructure';
import { removeUnsaveableFilters } from 'modules/search/helpers/savedSearch/removeUnsaveableFilters';
import { serializeFiltersForSavedSearch } from 'modules/search/helpers/savedSearch/serializeFiltersForSavedSearch';
import {
  filterToIndex,
  makeSavedSearchLocation,
} from 'modules/search/helpers/savedSearches';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { saveSearch } from 'store/ducks/userEnvironment.actions';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useMainSelector } from 'store/hooks/useMainSelector';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { LISTING_TYPE_PLURAL_HUMAN_NAME_MAP } from 'utils/constants/general/listingTypePluralHumanNameMap';
import { objectEmpty } from 'utils/functional';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';
import { showModal } from 'zustand-stores/modalStore';

type Args = {
  searchFacets: SearchFacet[];
};

export function useSaveSearch({ searchFacets }: Args) {
  const { query, filters, searchLocation } = useMainSelector(
    (state) => state.mainSearch,
  );
  const { value: radius } = useMainSelector((state) => state.searchRadius);

  const dispatch = useMainDispatch();

  const location = useLocation();
  const { user } = useUserEnvironment();

  const showEmailSignupModal = (savedSearch: ApiSavedSearchUnsaved) => {
    const locationText = searchLocation?.text
      ? sprintf(getText('in %(locationText)s'), {
          locationText: searchLocation.text,
        })
      : getText('on Idealist');
    const listingTypeText =
      LISTING_TYPE_PLURAL_HUMAN_NAME_MAP[filters.type || 'ALL'];

    showModal('EMAIL_SIGNUP', {
      title: sprintf(
        getText('Get email alerts about %(listingTypeText)s %(locationText)s'),
        {
          listingTypeText,
          locationText,
        },
      ),
      savedSearch,
    });
  };

  const saveSearchInternal = () => {
    const search = {
      text: query,
      filters: serializeFiltersForSavedSearch({
        filtersById: filters,
        queryParams: queryString.parse(location.search),
        searchFacets,
      }),

      radius: radiusStringToInt(radius),
      location: searchLocation ? makeSavedSearchLocation(searchLocation) : {},

      index: filterToIndex(filters.type),
    };

    const loggedInVerified = Boolean(
      user?.email !== null && user?.emailVerified,
    );
    const informationRequired =
      user && (!user.firstName || !user.lastName || !user.email);

    if (loggedInVerified && !informationRequired) {
      dispatch(saveSearch(removeUnsaveableFilters(search)));
      return;
    }
    if (!loggedInVerified) {
      showEmailSignupModal(search);
    }
  };

  const hasFilters =
    query ||
    !objectEmpty(
      almostSavedSearchSerializableDataStructure({
        filtersById: filters,
        searchFacets,
      }),
    );

  // For volops, we do not want to include searches with only imported listings
  const filterIncludesOrgPostedActions =
    filters.type !== 'VOLOP' ||
    filters.actionTypeFacet === 'ALL' ||
    (Array.isArray(filters.actionTypeFacet) &&
      filters.actionTypeFacet.some((facet) =>
        ['VOLOP', 'EVENT'].includes(facet),
      ));

  return {
    saveSearch: () => deferAndStartRouteLoadingAnimation(saveSearchInternal),
    saveableSearch: Boolean(hasFilters && filterIncludesOrgPostedActions),
  };
}
